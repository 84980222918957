<template>
  <div class="columns">
     <div class="column is-one-third">
        <card-component
          title="1. Run posthoc tests"
          class="tile is-child"
          :displayHelp="$options.name"
        >
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click.prevent="run"
            >
              <span class="icon is-small"><i class="fa fa-cogs"></i></span>
              <span> Run post-hoc tests </span>
            </b-button>
          </div>
        </card-component>
      </div>
      <div class="column">
        <card-component title="2. Explore results of posthoc tests" ></card-component>
        <card-no-result v-if="!resultsLoaded">
        </card-no-result>
        <div v-else>
          <error :type='"notifications"' />
          <card-component v-if="hasResults">
            <b-tabs @input="displayResult">
              <b-tab-item v-if="basicTable" label="Table" icon="table" value="table" />
              <b-tab-item v-if="graphicals.length>0" label="Plots" icon="bar-chart" value="plots" />
              <b-tab-item v-if="png" label="Heatmap" icon="bar-chart" value="heatmap" />
              <b-tab-item v-if="posthocplot==='yes'" label="Posthocplot" icon="tasks" value="posthocplot" />
            </b-tabs>
          </card-component>
          <card-component v-show="currentResult==='plots'">
          <plotly
              v-if="graphicals.length===1"
              :data="graphicals[0]"
            ></plotly>
            <plotly-list
              v-else-if="graphicals.length>1"
              :data="graphicals"
            ></plotly-list>
        </card-component>
        <card-component
            v-if="currentResult==='heatmap'"
          >
          <png v-if="png" :data=png />
        </card-component>
        <div v-if="currentResult==='table'">
          <card-component
            v-for="(bTable,index) in basicTables"
            :key="index"
            :title="bTable.title"
          >
            <basic-table
              :searchable="true"
              :sortable="true"
              :data="bTable"
            />
          </card-component>
        </div>
         <div v-if="currentResult==='posthocplot'">
          <card-component>
            <div class="has-text-centered">
              <b-field
                label="Select a differential expressed variable"
              >
                      <b-select
                        v-model="variable"
                        class="mb-2"
                        expanded
                      >
                      v-for="(group,index) in toDelete" :key="index" class=""
                        <option v-for="(value,index) in diffVars"
                          :key="index" :value="value">{{value}}</option>
                      </b-select>
              </b-field>
              <b-button rounded
                class="is-primary button-shadow"
                @click.prevent="plotResult"
              >
                <span class="icon is-small"><i class="fa fa-cogs"></i></span>
                <span> Plot results </span>
              </b-button>
            </div>
          </card-component>
          <card-component v-if="forestplot"  title="Forest plot">
            <plotly
              :data="forestplot"
            ></plotly>
            <nav class="level" >
          <div class="level-left" />
          <div class="buttons level-right">
            <r-save-for-report
              label="Add to report"
              :objectName="postHocObjectName"
              :params="paramsPlot"
            />
          </div>
        </nav>
          </card-component>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ranalysis } from '@/mixins/ranalysis'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useErrorStore } from '@/stores/error'

export default {
  name: 'RPosthocTests',
  components: {
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'basic-table': () => import('@/components/rresults/BasicTable.vue'),
    'r-save-for-report': () => import('@/components/RSaveForReport.vue'),
    'png': () => import('@/components/rresults/PNG.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  props: {
    dataset: {
      type: String,
      default: null
    },
    diffVars: {
      type: Array,
      default: null
    },
    posthocplot: {
      type: String,
      default: 'no'
    }
  },
  mixins: [ranalysis],
  data () {
    return {
      currentResult: 'table',
      postHocObjectName: null,
      variable: '',
      forestplot: null
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const errorStore = useErrorStore()
    return { workspaceStore, errorStore }
  },
  computed: {
    params: function () {
      return {
        'func_name': 'r_posthoc_tests',
        'datasetName': this.dataset,
        'out_graph': true
      }
    },
    paramsPlot: function () {
      return {
        'func_name': 'r_posthoc_plots',
        'datasetName': this.dataset,
        'variableName': this.variable
      }
    }
  },
  methods: {
    afterRun: function (data) {
      this.postHocObjectName = data.ObjectName
      if ('Conditions' in data) {
        this.$emit('is-posthoc-computed',
          { 'conditions': data.Conditions,
            'objectName': data.ObjectName,
            'hasSign': data.HasSign })
      } else {
        this.$emit('is-posthoc-computed',
          { 'conditions': [],
            'objectName': data.ObjectName,
            'hasSign': data.HasSign })
      }
    },
    displayResult: function (value) {
      this.currentResult = value
    },
    plotResult: function () {
      this.workspaceStore.runRFunction({
        'func_name': 'r_posthoc_plots',
        'datasetName': this.dataset,
        'variableName': this.variable,
        'out_graph': true
      })
        .then(data => {
          this.forestplot = data.ForestPlot
          if ('Messages' in data) {
            this.errorStore.setNotifications(data.Messages.data)
          }
        })
    }
  },
  mounted () {
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
